<template>
  <div class="text-center" 
       v-loading="loading || fetchingPrices || checkingSubscription || isProcessing"
       :element-loading-text="loadingMessage"
       element-loading-spinner="el-icon-loading"
       element-loading-svg-view-box="-10, -10, 50, 50"
       element-loading-background="rgba(0, 0, 0, 0.3)">
    
    <h1 class="text-4xl font-bold mb-4">{{ $t('ChoosePlan') }}</h1>
    <h3 class="text-xl text-gray-600 mb-8">{{ $t("PayHint") }}</h3>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div v-for="plan in plans" :key="plan.id" 
           :class="['plan-card hover:shadow-xl', plan.bgClass, 'shadow-lg', 'rounded-lg', 'p-6']">
        <img :src="VIP" alt="VIP Image" class="mx-auto mb-4 w-16 h-16">
        <h3 :class="['text-2xl font-semibold mb-2', plan.titleColor]">
          {{ $t(plan.titleKey) }}
        </h3>
        <p :class="['text-lg mb-2', plan.textColor]">
          {{ prices[plan.priceId]?.formattedPrice || plan.price }}{{ $t(plan.timeKey) }}
        </p>
        <p :class="['text-sm mb-4', plan.hintColor]">
          {{ $t(plan.hintKey) }}
        </p>
        <el-button :type="plan.buttonType" 
                   :loading="isProcessing"
                   @click="openCheckout(plan)">
          {{ $t(plan.buttonKey || 'Subscribe') }}
        </el-button>
      </div>
    </div>

    <p class="mt-8 text-gray-500">Copyright © emperinter</p>
  </div>
</template>

<script>
import VIP from '@/assets/vip.png'


export default {
  name: 'PricePage',
  data() {
    return {
      VIP,
      loading: false,
      fetchingPrices: false,
      checkingSubscription: false,
      isProcessing: false,
      prices: {},
      plans: [
        {
          id: 'annual',
          priceId: 'pri_01hv37ntpgqgf0nf4vhkzy6st5',
          price: '$19.99',
          bgClass: 'bg-red-100',
          titleColor: 'text-red-800',
          textColor: 'text-red-700',
          hintColor: 'text-red-600',
          buttonType: 'danger',
          titleKey: 'AnnualVIP',
          timeKey: 'AnnualVIPTime',
          hintKey: 'AnnualVIPHint',
          days: 365
        },
        // {
        //   id: 'quarterly',
        //   priceId: 'pri_01hv37kcen5k323v7jzq03a3hh',
        //   price: '$8.88',
        //   bgClass: 'bg-green-100',
        //   titleColor: 'text-green-800',
        //   textColor: 'text-green-700',
        //   hintColor: 'text-green-600',
        //   buttonType: 'success',
        //   titleKey: 'QuartelyVIP',
        //   timeKey: 'QuartelyVIPTime',
        //   hintKey: 'QuartelyVIPHint',
        //   days: 93
        // },
        {
          id: 'monthly',
          priceId: 'pri_01htq3vq91c18n974hrf84c17q',
          price: '$1.99',
          bgClass: 'bg-blue-100',
          titleColor: 'text-blue-800',
          textColor: 'text-blue-700',
          hintColor: 'text-blue-600',
          buttonType: 'primary',
          titleKey: 'MonthlyVIP',
          timeKey: 'MonthlyVIPTime',
          hintKey: 'MonthlyVIPHint',
          days: 31
        },
        {
          id: 'daily',
          priceId: 'pri_01htq3wm34engpcy5905fc3hwp',
          price: '$1.00',
          bgClass: 'bg-yellow-100',
          titleColor: 'text-yellow-800',
          textColor: 'text-yellow-700',
          hintColor: 'text-yellow-600',
          buttonType: 'primary',
          titleKey: 'DailyPlan',
          timeKey: 'DailyPlanTime',
          hintKey: 'DailyPlanHint',
          buttonKey: 'Pay',
          days: 1
        }
      ]
    }
  },

  computed: {
    loadingMessage() {
      if (this.loading) return this.$t('Loading')
      if (this.fetchingPrices) return this.$t('FetchingPrices')
      if (this.checkingSubscription) return this.$t('Subscribing')
      if (this.isProcessing) return this.$t('Processing')
      return ''
    }
  },

  async mounted() {
    this.loading = true
    try {
      await this.initializePaddle()
      await this.fetchPrices()
      this.checkSubscriptionStatus()
    } catch (error) {
      console.error('初始化失败:', error)
      this.$message.error(this.$t('InitError'))
    } finally {
      this.loading = false
    }
  },

  methods: {
    async initializePaddle() {
      // eslint-disable-next-line no-undef
      Paddle.Initialize({
        token: 'live_08ccd4dc3ab91634d0586e4b147',
        eventCallback: this.handlePaddleEvent
      })
    },

    async fetchPrices() {
      this.fetchingPrices = true
      try {
        const items = this.plans.map(plan => ({
          priceId: plan.priceId,
          quantity: 1
        }))

        // eslint-disable-next-line no-undef
        const result = await Paddle.PricePreview({ items })
        
        this.prices = result.data.details.lineItems.reduce((acc, item) => {
          acc[item.price.id] = {
            formattedPrice: item.formattedTotals.total,
            amount: item.totals.total
          }
          return acc
        }, {})
      } catch (error) {
        console.error('价格获取失败:', error)
        this.$message.warning(this.$t('PriceFetchError'))
      } finally {
        this.fetchingPrices = false
      }
    },

    checkSubscriptionStatus() {
      const purchaseStatus = localStorage.getItem('purchaseStatus')
      const expirationTime = localStorage.getItem('expirationTime')
      
      if (purchaseStatus === 'purchased' && expirationTime) {
        const expDate = new Date(expirationTime)
        if (expDate > new Date()) {
          return
        }
      }
      
      localStorage.removeItem('purchaseStatus')
      localStorage.removeItem('expirationTime')
    },

    async openCheckout(plan) {
      this.isProcessing = true
      this.checkingSubscription = true
      
      // 使用 nextTick 确保状态更新后再执行后续操作
      await this.$nextTick()
      
      try {
        // eslint-disable-next-line no-undef
        const checkoutResult = await Paddle.Checkout.open({
          items: [{ 
            priceId: plan.priceId, 
            quantity: 1 
          }],
          settings: {
            displayMode: 'overlay',
            theme: 'light',
            locale: this.$i18n.locale
          }
        })
        
        if (checkoutResult) {
          this.$message.success(this.$t('CheckoutOpened'))
        }
      } catch (error) {
        console.error('支付发起失败:', error)
        this.$message.error(this.$t('CheckoutError'))
      } finally {
        // 确保状态重置
        setTimeout(() => {
          this.isProcessing = false
          this.checkingSubscription = false
        }, 500)
      }
    },
    handlePaddleEvent(data) {
      if (data.name === 'checkout.completed') {
        const item = data.data.items[0]
        const plan = this.plans.find(p => p.priceId === item.priceId)
        
        if (plan) {
          const expirationTime = new Date()
          expirationTime.setDate(expirationTime.getDate() + plan.days)
          
          localStorage.setItem('purchaseStatus', 'purchased')
          localStorage.setItem('plan_id', plan.id)
          localStorage.setItem('expirationTime', expirationTime.toISOString())
          
          this.$message.success(this.$t('PurchaseSuccess'))
        }
      }
    }
  }
}
</script>

<style scoped>
.plan-card {
  transition: all 0.3s ease;
}

.plan-card:hover {
  transform: translateY(-5px);
}

.plan-card-daily{
  text-align: center;
  background-color: #bdbfc5;
  color: rgb(0, 0, 0);
}

.plan-card-monthly{
  text-align: center;
  background-color: #604800;
  color: aquamarine;
}

.plan-card-quartely{
  text-align: center;
  background-color: #494826;
  color:#f0f5ff;
}

.plan-card-annual{
  text-align: center;
  background-color: #000000;
  color: #fff700;
}

img {
  /* 设置图像宽度 */
  width: 38px;
  /* 设置图像高度 */
  height: 38px;
  /* 设置图像居中 */
  margin: 0 auto;
  /* 设置图像垂直居中 */
  display: block;
}

/* 自定义loading样式 */
:deep(.el-loading-text) {
  color: #ffffff;
  font-size: 16px;
}

:deep(.el-loading-spinner i) {
  color: #ffffff;
  font-size: 30px;
}
</style>
